/* font styles */
:root {
    --font-primary: "instrument sans", sans-serif;
}

/* color styles */
:root {
    --color-primary: #1861DD;
    --color-primary-hover: #1B4EB2;
    --color-primary-light: #DAEEFF;
    --color-primary-selection: #EFF8FF;
    --color-outline: #E3E8EF;
    --color-font: #363636;
    --color-disabled: #CDD5DF;
    --overlay-shade1: rgba(0, 0, 0, .60);
    --white: #fff;
    --error: #F04438;
    --neutral-05: #F9F9FB;
    --grey-200: #E3E8EF;
    
}



/* font styles */
.title.is-4 {
    /* refer to h1 in design */
    font-family: var(--font-primary);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}

.title.is-5 {
    /* refer to h2 in design */
    font-family: var(--font-primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}

.title.is-6 {
    /* refer to h3 in design */
    font-family: var(--font-primary);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
}

.title.is-7 {
    /* refer to h4 in design */
    font-family: var(--font-primary);
    color: var(--color-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 120% */
}

.label, th, .switch[type=checkbox]+label {
    /* refer to body-bold in design */
    font-family: var(--font-primary);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 22px !important; /* 142.857% */
}

.input, p, td, .select, .textarea, body, .tabs, span, .citation  {
    /* refer to body in deisgn */
    /* .select is for the select dropdown componenet */
    font-family: var(--font-primary);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 450 !important;
    line-height: 22px !important; /* 142.857% */
}

.citation:hover a {
    color: var(--color-primary-hover);
    text-decoration: underline;
}

.p.bold {
    /* refer to body (bold) in deisgn */
    /* .select is for the select dropdown componenet */
    font-family: var(--font-primary);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 22px !important; /* 142.857% */
}

/* font label for buttons */
.button, .file-label {
    /* refer to button-label in design*/
    font-family: var(--font-primary);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    transition: background-color 0.3s ease;
}



/* component colors */
.button.is-primary, .button.is-link {
    background-color: var(--color-primary);
}

.button.is-primary:hover, .button.is-link:hover {
    background-color: var(--color-primary-hover);
    /* background-color: #000; */
}

.button.is-success, .button.is-success[disabled] {
    background-color: var(--color-primary);
    border-color: rgba(0, 0, 0, 0);
}

.button.is-success:hover {
    background-color: var(--color-primary-hover);
}

.button.is-secondary {
    border-color: var(--color-primary);
    color: var(--color-primary);
}

.button.is-secondary:hover {
    color: var(--color-primary-hover);
    border-color: var(--color-primary-hover);
    background-color: var(--color-primary-light);
}

.button.icon {
    width: auto;
    display: inline-flex;
    align-items: center;
    padding: 4px;
    border: 0px solid #fff;
    background: none;
    min-height: 0;
    height: auto;
    border-radius: 6px;
}

.button.icon:hover {
    background: var(--color-disabled);
}

tr.is-selected {
    background-color: var(--color-primary-light) !important;
    color: var(--color-font) !important;
}

.modal-background {
    /* This is the overlay between the screen and modal*/
    background-color: var(--overlay-shade1);
}

.modal-content {
    /* Bakcground of the modal window */
    background-color: var(--white);
}

.modal-close::after, .modal-close::before {
    /* color for the cross icon */
    background-color: var(--color-primary);
}

.modal-close:hover {
    /* bacground color for the cross icon when hovered */
    background-color: rgba(0, 0, 0, .10);
}

.switch[type=checkbox].is-info:checked+label::before,
.switch[type=checkbox].is-info:checked+label:before {
    /* background color for the switch toggle when turned on */
    background: var(--color-primary) !important;
}

.button.is-small.icon-button:hover.icon-button:hover {
    background-color: rgba(0, 0, 0, .10);
}

.icon.is-danger {
    color: var(--error);
}

.tabs.is-toggle li.is-active a {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--white);
    z-index: 1;
}



/* component styles */
.App {
    display: flex;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    height: calc(100vh - 80px);
}

.split.left, .split.right {
    background-color: transparent;
    overflow-y: auto;
}

.box.is-draft {
    border-radius: 12px;
    border: 1px solid var(--color-outline);
    box-shadow: none;
}

.button {
    border-radius: 10px;
    min-height: 40px;
}

.button.is-secondary {
    border-radius: 10px !important;
    min-height: 40px;
}

.card {
    border-radius: 12px;
    background: var(--white);
    box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    overflow: hidden;
}

.card-content{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    padding: 0px 4px 0px 4px;
    height: 100%;
    width: 100%;
}

.card-subcontent{
    padding: 16px 0px;
    border-bottom: 1px solid #CDD5DF;
}

.card-subcontent:first-child {
    padding-top: 0; /* Remove top padding for the first div */
}

.card-subcontent:last-child {
    padding-bottom: 0; /* Remove bottom padding for the last div */
    border-bottom: none; /* Remove bottom border for the last div */
}

.card-header {
    display: flex;
    padding: 20px 8px 20px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    box-shadow: none;
}

.card-header.is-expanded {
    background-color: var(--color-outline);
  }

.card-header-title {
    padding: 0;
}

.card-header-icon {
    padding: 0px 16px 0px 4px;
}

.card-subheader {
    display: flex;
    align-items: left;
    gap: 4px;
    align-self: stretch;
    margin-bottom: 4px;
}

.card-subheader.title {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
}

.case-details {
    margin-top: 10px;
}
  
.case-detail-item {
    display: flex;
    padding: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.case-detail-header.no-margin {
    margin-bottom: 0 !important;
}

.case-detail-header {
    align-self: stretch;
}
  
.case-detail-body {
    align-self: stretch;
}

.citation-link {
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #E9EDF1 ;
}

.citation-link:last-child {
    margin-bottom: 0px;
    border-bottom: none;
  }

.card-content > ul > li:last-child .citation-link {
    margin-bottom: 0px;
    border-bottom: none;
}

.content {
    padding: 16px 0px;
    flex: 1; /* Allow the .content div to stretch and fill available space */
    overflow-y: auto; /* Add scrolling if content overflows */
    width: 100%; /* Ensure .content stretches to the full width of .card-content */
    box-sizing: border-box; /* Include padding in width calculations */
}

.fixed-icon {
    flex-shrink: 0; /* Prevents shrinking */
    width: 16px !important; /* Force specific width */
    height: 16px !important; /* Force specific height */
    min-width: 16px !important;
    min-height: 16px !important;
  }

.label {
    padding-left: 4px;
    padding-top: 10px;
    padding-bottom: 7px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.input, .select {
    min-height: 44px;
    border-radius: 8px;
}

.input-group {
    border-radius: 24px;
    border: 1px solid var(--color-outline);
    background: var(--neutral-05);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
}

.input-group-sub {
    background-color: var(--white);
    padding: 12px;
    border-radius: 12px;
    border: 1px solid var(--grey-200);
}

.icon-container {
  display: flex; /* Use Flexbox for alignment */
  align-items: center; /* Vertically center the icon */
  justify-content: center; /* Optionally center horizontally, if needed */
  height: 100%; /* Ensure the container spans the full height of its parent */
}

.list, .list-legal {
    margin-bottom: 20px;
}

.list:first-child {
    margin-top: 12px;
}

.list-legal:last-child {
    margin-bottom: 0px;
}

.list:last-child {
    margin-bottom: 0px;
}

.list-legal {
    margin-top: 0px;
}

.section {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 24px;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--color-outline);
}

.section:last-child {
    border-bottom: none; /* Remove bottom border for the last .section */
}

.textarea {
    border-radius: 8px;
}

.table td {
    vertical-align: middle;
}

.modal-content {
    padding: 24px;
    border-radius: 12px;
    max-width: 480px;
}

.select, .dropdown-container select {
    max-width: 100%;
    width: 100%;
    background-color: #ffffff;
    border-color: #dbdbdb;
    color: #363636;
    align-items: center;
    display: inline-flex;
    height: 2.5em;
    justify-content: flex-start;
    padding-bottom: calc(.5em - 1px);
    padding-left: calc(.75em - 1px);
    padding-right: calc(.75em - 1px);
    padding-top: calc(.5em - 1px);
    position: relative;
    vertical-align: top;
}

.modal-close:hover {
    border-radius: 6px;
}

.navbar {
    display: flex;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    padding: 24px 20px;
}

.tabs.is-toggle {
    margin-bottom: 40px;
}

.tabs.is-toggle ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}
  
.tabs.is-toggle li {
    flex: 1;
}
  
.tabs.is-toggle li a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.file-cta {
    border-radius: 10px !important;
    border-color: var(--color-primary);
    background-color: var(--white);
    color: var(--color-primary);
    min-height: 40px;
}

.file-cta:hover {
    background-color: var(--color-primary-light) !important;
    color: var(--color-primary-hover) !important;
}

.block {
    margin-bottom: 8px;
}

.upload-container {
    padding: 24px;
    border: 1px dashed #b6b6b6;
    border-radius: 10px;
    margin: 20px 0px;
    background-color: var(--white) ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* START of styles for the progress bar in drafters */
.tabs.is-toggle.is-progress {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    align-items: center; /* Centers the <ul> vertically */
    padding-bottom: 20px;
    margin-bottom: 36px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.tabs.is-toggle.is-progress ul {
    gap: 0.4rem;
    flex-grow: 0;
}

.tabs.is-toggle.is-progress ul li {
    display: inline-flex; /* Change display to inline-flex to hug content */
    align-items: center; /* Center align items vertically */
    position: relative;
    padding-right: 1.3rem; /* Space out the lines */
}

.tabs.is-toggle.is-progress ul li::after {
    content: "";
    display: block;
    width: 1rem; /* Width of the line */
    height: 2.4px; /* Full height of the <li> */
    background: #ccc; /* line color */
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* Media query for viewports 1400px and wider */
@media (min-width: 1500px) {
    .tabs.is-toggle.is-progress ul {
        gap: 0.5rem;
    }
    
    .tabs.is-toggle.is-progress ul li {
        padding-right: 1.7rem; /* Space out the lines */
    }
    
    .tabs.is-toggle.is-progress ul li::after {
        width: 1.2rem; /* Increased width of the line */
    }
}
@media (min-width: 1920px) {
    .tabs.is-toggle.is-progress ul {
        gap: 0.8rem;
    }
    
    .tabs.is-toggle.is-progress ul li {
        padding-right: 3.3rem; /* Space out the lines */
    }
    
    .tabs.is-toggle.is-progress ul li::after {
        width: 2.5rem; /* Increased width of the line */
    }
}

.tabs.is-toggle.is-progress a {
    border-style: hidden;

}

.tabs.is-toggle.is-progress ul li:last-child::after {
    content: none; /* Remove the line after the last <li> */
}

.tabs.is-toggle.is-progress ul li:last-child {
    padding-right: 0px; /* Remove last child's padding*/
}

.tabs.is-toggle.is-progress li a {
    border-radius: 8px; /* Border radius for each tag */
}

.tabs.is-toggle.is-progress li:first-child a {
    border-radius: 8px; /* Border radius for first tag */
}

.tabs.is-toggle.is-progress li:last-child a {
    border-radius: 8px; /* Border radius for last tag */
}
/* END of styles for the progress bar in drafters */

.button .icon:first-child:not(:last-child) {
    margin-left: -4px;
}

/* Interactions */

.no-hover:hover {
    background-color: transparent !important;
    cursor: default !important;
}

.tag {
    display: inline-block;
    padding: 2px 8px !important;
    color: white !important;
    border-radius: 6px !important;
    text-align: center;
    width: 82px;
  }
  
  .tag.met {
    background-color: #16B364; /* Green for "Met" */
  }
  
  .tag.not-met {
    background-color: #D92D20; /* Red for "Not Met" */
  }

  .table-row {
    padding: 16px 0px;
    border-bottom: 1px solid #E9EDF1;
  }

  .table-row:last-child {
    border-bottom: 0;
    padding-bottom: 0px;
  }

  .hide-scrollbar {
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
  }